import React from "react";
import {
  Avatar,
  Col,
  Panel,
  Stack,
  Tag
} from "rsuite";

export default function contactDisplayPanelPerson({ name, title, avatar }) {
  return (
    <Col xs={8} style={{ marginBottom: "10px" }}>
      <Panel
        bordered
        style={{ backgroundColor: "white", width: "100%", height: "100%", marginBottom: "10px" }}
        header={
          <Stack justifyContent="space-between">
            <span>{name}</span>
            {avatar && (
              <Avatar circle style={{ background: "#000" }} src={avatar} imgProps={{ objectFit: "cover"}} /> 
            )}
          </Stack>
        }
      >
        <Tag>{title}</Tag>
      </Panel>
    </Col>
  );
}
