import * as React from "react";
import { Grid, Row, Col, Panel, FlexboxGrid } from "rsuite";
import LayoutMain from "components/layout/main";
import HeaderLarge from "components/section/headerLarge";
import SectionWrapper from "components/section/sectionWrapper";
import ContactDisplayPanelPerson from "components/ui/contactDisplayPanelPerson";
import { StaticImage } from "gatsby-plugin-image";

const OverPage = () => {
  return (
    <LayoutMain>
      <HeaderLarge
        title="Over het Kennisplatform"
        description="‘Elke patiënt, arts, tandarts of verpleegkundige weet de weg te vinden naar goede expertise over antistolling’."
      />

      <SectionWrapper padded background>
        <Grid>
          <Row style={{ marginBottom: "20px", fontSize: "18px" }}>
            <Col xs={18}>
              <h2 style={{ color: "#000", marginBottom: "20px" }}>
                Onze missie
              </h2>
              <p style={{ lineHeight: "2" }}>
                Met dit Kennisplatform willen we uitwisseling van kennis op alle
                terreinen van antistollingszorg bevorderen: Het beschikbaar
                stellen van actuele informatie betreffende de praktijk van
                antistollingszorg aan professionals, teneinde de efficiëntie en
                veiligheid van diagnostiek en behandeling te versterken.
                Indirect informatie betreffende antistollingszorg beschikbaar
                stellen voor patiënten en haar/zijn mantelzorgers.
              </p>
              <h3 style={{ color: "#000", marginTop: "40px" }}>
                Binnen het Kennisplatform is het volgende te vinden
              </h3>
              <p style={{ lineHeight: "2" }}>
                <ul style={{ paddingInlineStart: "15px", marginTop: "15px" }}>
                  <li>
                    experts op het gebied van antistolling - en met hun
                    bereikbaarheid in de verschillende ziekenhuizen in Nederland
                  </li>
                  <li>
                    regionale samenwerkingsverbanden, expertisecentra en
                    trombosediensten
                  </li>
                  <li>lokale diagnostiek en behandelprotocollen</li>
                  <li>richtlijnen en andere kennisinstrumenten</li>
                  <li>e-learnings</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>

      <SectionWrapper padded>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h3 style={{ color: "#000", marginBottom: "20px" }}>
                Deelnemende organisaties
              </h3>
            </Col>
          </Row>
          <FlexboxGrid
            style={{
              alignItems: "stretch",
              width: "1120px",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          >
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-niv.png"
                    alt="Logo Nederlandse Internisten Vereniging"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Nederlandse Internisten Vereniging</h5>
              </Panel>
            </Col>
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-nvvc.png"
                    alt="Logo Trombosestichting"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Nederlandse Vereniging Voor Cardiologie</h5>
              </Panel>
            </Col>
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-nvvh.png"
                    alt="Logo Trombosestichting"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Nederlands Vereniging Voor Heelkunde</h5>
              </Panel>
            </Col>
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-nhg.png"
                    alt="Logo Trombosestichting"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Nederlands Huisartsen Genootschap</h5>
              </Panel>
            </Col>
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-fnt.png"
                    alt="Logo Trombosestichting"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Federatie van Nederlandse Trombosediensten</h5>
              </Panel>
            </Col>
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-ctd.png"
                    alt="Logo Trombosestichting"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Cliëntenraad Trombose Diensten Nederland</h5>
              </Panel>
            </Col>
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-fms.png"
                    alt="Logo Trombosestichting"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Federatie Medisch Specialisten</h5>
              </Panel>
            </Col>
            <Col xs={8} style={{ marginBottom: "10px" }}>
              <Panel
                bordered
                style={{
                  backgroundColor: "white",
                  height: "100%",
                  marginBottom: "10px",
                }}
                header={
                  <StaticImage
                    layout="fixed"
                    src="../../images/logo/logo-skms2.png"
                    alt="Logo Trombosestichting"
                    style={{ height: "30px", maxWidth: "33%" }}
                    objectFit="contain"
                  />
                }
              >
                <h5>Stichting Kwaliteitsgelden Medisch Specialisten 2</h5>
              </Panel>
            </Col>




          </FlexboxGrid>
        </Grid>
      </SectionWrapper>

      <SectionWrapper padded background>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h3 style={{ color: "#000", marginBottom: "20px" }}>
                De voorbereidingswerkgroep
              </h3>
            </Col>
          </Row>
          <FlexboxGrid
            style={{
              alignItems: "stretch",
              width: "1120px",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          >
            <ContactDisplayPanelPerson
              name="Dhr. prof. dr. M.V. (Menno) Huisman"
              title="Voorzitter - Internist"
            />
            <ContactDisplayPanelPerson
              name="Dhr. prof. dr. H. (Hugo) ten Cate"
              title="Voorzitter - Internist"
            />
            <ContactDisplayPanelPerson
              name="Mevr. prof. dr. K. (Karina) Meijer"
              title="Internist"
            />
            <ContactDisplayPanelPerson
              name="Mevr. Dr. L.M. (Laura) Faber"
              title="Internist"
            />
            <ContactDisplayPanelPerson
              name="Dhr. Prof. dr. F.A. (Erik) Klok"
              title="Internist"
            />
            <ContactDisplayPanelPerson
              name="Dhr. dr. G.J. (Geert Jan) Geersing"
              title="Huisarts"
            />
            <ContactDisplayPanelPerson
              name="Dhr. dr. M.E.W. (Martin) Hemels"
              title="Cardioloog"
            />
            <ContactDisplayPanelPerson
              name="Dhr. dr. M. (Michiel) Warlé"
              title="Chirurg"
            />
          </FlexboxGrid>
        </Grid>
      </SectionWrapper>

      <SectionWrapper padded>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h3 style={{ color: "#000", marginBottom: "20px" }}>
                De klankbordgroep
              </h3>
            </Col>
          </Row>
          <FlexboxGrid
            style={{
              alignItems: "stretch",
              width: "1120px",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          >
            <ContactDisplayPanelPerson
              name="Dhr. dr. A.A.W. (Arno) Roest"
              title="Kinderarts"
            />
            <ContactDisplayPanelPerson
              name="Mevr. dr. C.H. (Heleen) van Ommen"
              title="Kinderarts"
            />
            <ContactDisplayPanelPerson
              name="Dhr. drs. W.W.L. (Wilson) Li"
              title="Chirurg"
            />
            <ContactDisplayPanelPerson
              name="Dhr. prof. dr. P.W. (Pieter Willem) Kamphuisen"
              title="Internist"
            />
            <ContactDisplayPanelPerson
              name="Mevr. E. (Esther) Nossent"
              title="Longarts"
            />
            <ContactDisplayPanelPerson
              name="Dhr. Dr. S.H. (Steven) Renes"
              title="Anaesthesioloog"
            />
            <ContactDisplayPanelPerson
              name="Mevr. dr. M.J. (Marie Josee) van Rijn"
              title="Chirurg"
            />
            <ContactDisplayPanelPerson
              name="Mevr. dr. M.F. (Margreet) Herwaarden"
              title="Apotheker"
            />
            <ContactDisplayPanelPerson
              name="Dhr. dr. P. (Peter) Damman"
              title="Cardioloog"
            />
            <ContactDisplayPanelPerson
              name="Dhr. dr. H.B. (Harm) Ettema"
              title="Orthopeed"
            />
            <ContactDisplayPanelPerson
              name="Dhr. dr. B. (Banne) Nemeth"
              title="AIOS orthopedie"
            />
            <ContactDisplayPanelPerson
              name="Mevr. dr. J.A.E. (Julie) Staals"
              title="Neuroloog"
            />
            <ContactDisplayPanelPerson
              name="Dhr. dr. R.A. (Richard) Faaij"
              title="Apotheker"
            />
            <ContactDisplayPanelPerson
              name="Mevr. dr. M.J.H.A. (Marieke) Kruip"
              title="Internist"
            />
            <ContactDisplayPanelPerson
              name="Dhr. W.F. (Wim) Buding"
              title="Patiëntenperspectief"
            />
            <ContactDisplayPanelPerson
              name="Mevr. A.C.C. (Anja) de Bruin"
              title="Patiëntvertegenwoordiger"
            />
          </FlexboxGrid>
        </Grid>
      </SectionWrapper>

      {/* <SectionWrapper padded background>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col xs={12}>
              <h3 style={{ marginBottom: "20px" }}>Contact opnemen</h3>
              <Placeholder.Paragraph />
            </Col>
            <Col xs={12}>
              <ContactFormGeneral />
            </Col>
          </Row>
        </Grid>
      </SectionWrapper> */}
    </LayoutMain>
  );
};

export default OverPage;
